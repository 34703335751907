.page-top-bar {
    width: 100%;
    text-align: center;
    background: #23449c;
    margin-top: 48px;
    padding: 32px;
}

.page-top-bar h1 {
    font-size: 32px;
    margin: 0;
    color: #ffffff;
}