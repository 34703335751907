.custom-footer {
    background: #15225c;
    padding: 64px 128px;
    display: flex;
    flex-direction: column;
    color: #ffffff;
}

.custom-footer-info-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 128px;
}

.custom-footer-info-cell {
    display: flex;
    flex-direction: column;
}

.custom-footer-info-cell p {
    margin: 0
}

.custom-footer-qr-row {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    gap: 4px;
}

.custom-footer-contact-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.custom-footer-youtube-background {
    position: relative; /* Creates a positioning context for the pseudo-element */
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.custom-footer-youtube-background::before {
    content: '';
    position: absolute;
    width: 12px; /* Smaller background size */
    height: 12px; /* Adjust as needed */
    background: #ffffff; /* Background color */
    border-radius: 50%; /* Makes the background a circle */
    z-index: 0; /* Sends the background behind the icon */
}

.custom-footer-youtube-icon {
    z-index: 1;
}

.custom-footer-qr-content {
    display: flex;
    flex-direction: column;
    justify-content: normal;
}

@media only screen and (max-width: 700px) {

    .custom-footer-info-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 64px;
    }

    .custom-footer-contact-container {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .custom-footer {
        background: #15225c;
        padding: 32px;
        display: flex;
        flex-direction: column;
    }

    .custom-footer p {
        font-size: 14px;
    }

    .custom-footer-qr-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

}