.root-page-header {
    position: sticky;
    top: 0;
    z-index: 5;
    background: #15225c;
    display: flex;
    width: 100%;
    padding: 0 150px;
    height: 80px;
}

.root-page-header-logo-container {
    width: 512px;
    max-width: calc(100% - 128px);
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.root-page-header-logo {
    width: 100%;
    max-height: 100%;
    padding: 2px 0;
    cursor: pointer;
    display: flex;
}

.root-page-header-logo-small {
    max-height: 100%;
    padding: 4px 0;
    cursor: pointer;
    display: none;
}

.root-page-header-menu-vertical {
    width: 256px;
    display: none;
}

.root-page-header-menu {
    display: flex;
    flex: 1;
    min-width: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.root-page-header-menu-overflow-icon {
    height: 64px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.root-page-header-language-btn {
    width: 64px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.root-page-header-vertical-menu-btn {
    width: 64px;
    cursor: pointer;
    display: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.root-page-content {
    background: #ffffff;
    padding: 0 150px;
}

.root-footer {
    font-size: 14px;
    padding: 16px 0;
}

.suspense-fallback-container {
    height: 100vh;
}

@media only screen and (max-width: 700px) {

    .root-page-header {
        position: sticky;
        top: 0;
        z-index: 5;
        background: #15225c;
        display: flex;
        width: 100%;
        padding: 0 8px 0 12px;
    }

    .root-page-header-menu {
        display: none;
        flex: 1;
        min-width: 0;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    .root-page-header-vertical-menu-btn {
        width: 64px;
        cursor: pointer;
        padding: 0 24px 0 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .root-page-header-logo {
        max-height: 100%;
        padding: 4px 0;
        cursor: pointer;
        display: none;
    }

    .root-page-header-logo-small {
        max-height: 100%;
        padding: 6px 0;
        cursor: pointer;
        display: flex;
    }

    @keyframes appear {
        0% {
            opacity: 0;
            transform: translateY(-100%);
        }
        100% {
            opacity: 1;
            transform: translateX(0%);
        }
    }

    .root-page-header-menu-vertical {
        width: 100%;
        display: block;
        position: sticky;
        top: 80px;
        z-index: 5;

        animation: appear ease-in-out 1s forwards;
    }

    .root-page-content {
        background: #ffffff;
        padding: 0;
    }

    .root-footer {
        font-size: 12px;
        padding: 12px 0;
    }

}